import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom'; // Import Link from React Router
import './Navbar.css';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState('Home');

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  const updateTitle = (title) => {
    setCurrentPage(title);
    document.title = `${title} - Rohan Auto Service`;
  };

  const handleLinkClick = (title, path) => { // Modify to include path parameter
    updateTitle(title);
    closeNav();
    window.history.pushState({}, '', path); // Change URL without reloading page
  };

  useEffect(() => {
    const handleResize = () => {
      setIsNavOpen(window.innerWidth >= 600);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`navbar ${isNavOpen ? 'open' : ''}`}>
      <div className="logo-container">
        <a href='/'>
          <img
            className="logo"
            src="/logo4.png"
            alt="Logo"
          />
        </a>
      </div>
      <button className="toggle-button" onClick={toggleNav}>
        ☰
      </button>
      <ul style={{ display: isNavOpen || window.innerWidth >= 600 ? 'flex' : 'none' }} className="nav-links">
        <li>
          <ScrollLink
            to="home"
            smooth={true}
            duration={500}
            spy={true}
            offset={-50}
            activeClass="active-link"
            onSetActive={() => updateTitle('Mobile Car Repair & Home Services in Dubai')}
            onClick={() => handleLinkClick('Mobile Car Repair & Home Services in Dubai', '/')}
          >
            Home
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            offset={-50}
            activeClass="active-link"
            onSetActive={() => updateTitle('About Rohan Auto Mechanics: Trusted Mobile Car Repair Service in Dubai')}
            onClick={() => handleLinkClick('About Rohan Auto Mechanics: Trusted Mobile Car Repair Service in Dubai', '/about')}
          >
            About
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="services"
            smooth={true}
            duration={500}
            spy={true}
            offset={-50}
            activeClass="active-link"
            onSetActive={() => updateTitle('Our Services: Mobile Auto Repair & Home Services in Dubai')}
            onClick={() => handleLinkClick('Our Services: Mobile Auto Repair & Home Services in Dubai', '/services')}
          >
            Services
          </ScrollLink>
        </li>
        <li>
          <a href='https://blogs.rohanautomechanics.com'>
            Blogs
          </a>
        </li>
        <li>
          <ScrollLink
            to="bookingForm"
            smooth={true}
            duration={500}
            spy={true}
            offset={-50}
            activeClass="active-link"
            onSetActive={() => updateTitle('Book Rohan Mechanics: Mobile Auto Repair & Home Services in Dubai')}
            onClick={() => handleLinkClick('Book Rohan Mechanics: Mobile Auto Repair & Home Services in Dubai', '/booking')}
          >
            <button className='bookNow'>Book Now</button>
          </ScrollLink>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
