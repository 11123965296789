import React from 'react';
import { Helmet } from 'react-helmet'
import './Home.css';

const Home = () => {
  return (
    <div id="home" className="home-container">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MP2JL63NHS"></script>
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());

          gtag('config', 'G-MP2JL63NHS');
        `}
        </script>
        <meta name="description" content="Discover Rohan Auto Mechanics in Dubai, your trusted choice for mobile car repair and home services in Dubai. Get expert assistance wherever you are." />
      </Helmet>
      <div className="background-container">
        <img
          className="background-image"
          src='/carMechanic.jpg' 
          alt="Background"
        />
      </div>
      <div className="centered-text">
          <p>Professional</p>
          <h1>AUTO REPAIR AND HOME SERVICES</h1>
          <p>Your Trusted Auto Repair Service</p>
        </div>
      <div className="footerhome">
        <div className="footer-section">
          <img src="/available.png" alt="available" className="icon" />
          <p>Available from Monday - Sunday</p>
        </div>
        <div className="footer-section">
          <img src="/callIcon.png" alt="callIcon" className="icon" />
          <p>+971 (567) 017 979</p>
        </div>
        <div className="footer-section">
          <img src="/email.png" alt="email" className="icon" />
          <p>info@rohanautomechanics.com</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
