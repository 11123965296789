import React from 'react';
import { Helmet } from 'react-helmet';
import './AboutUs.css';
import ImageSlider from '../ImageSlider/ImageSlider'

const AboutUs = () => {

  const images = Array.from({ length: 22 }, (_, i) => `images/image${i + 1}.png`);
  
  return (
    <div id="about" className="aboutus-container">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MP2JL63NHS"></script>
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());

          gtag('config', 'G-MP2JL63NHS');
        `}
        </script>
        <meta name="description" content="Learn more about Rohan Auto Mechanics, the leading provider of trusted mobile car repair services in Dubai. With years of experience, we're committed to delivering top-notch service." />
      </Helmet>
      <div className='about-section-container'>
        <section className="about-section">
          <h2 className='title-about'>About Us</h2>
          <div className="description">
            <h1 className='nameBig'>Rohan Mobile Auto Mechanics in Dubai</h1>
            <p>brings the expertise of professional automotive repair and maintenance services right to your doorstep." </p>
            <p>Our mission is to provide reliable, efficient, and high-quality automotive services that exceed our customers' expectations.</p>
            <p>We aim to be the trusted partner for automotive care, known for our commitment to excellence, innovation, and customer satisfaction.</p>
          </div>
        </section>

        <section className="mission-section">
        <div className='Brands-Image'>
          <ImageSlider images={images}/>
        </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
