import React from 'react';
import { Helmet } from 'react-helmet';
import './Services.css';




const Services = () => {
  const servicesData = [
      { name: 'Oil Change', image: 'oilChange.png' },
      { name: 'Brake Services', image: 'brakeServices.png' },
      { name: 'Engine Repair', image: 'engineRepair.png' },
      { name: 'Battery Repair', image: 'batteryRepair.png' },
      { name: 'Transmission Repair', image: 'transmissionRepair.png' },
      { name: 'Suspension Repair', image: 'suspensionRepair.png' },
      { name: 'Vehicle Electrical Repair', image: 'vehicleElectricalRepair.png' },
      { name: 'AC Repair & Maintenance', image: 'acRepair.png' },
      { name: 'All Car Mechanical Services', image: 'allCar.png' },
    ];

    
  return (
    <div id="services" className='services-container'>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MP2JL63NHS"></script>
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());

          gtag('config', 'G-MP2JL63NHS');
        `}
        </script>
        <meta name="google-adsense-account" content="ca-pub-1836037034957702"/>
        <meta name="description" content="Get in touch with Rohan Mechanics for all your mobile auto repair and home service needs in Dubai. Contact us today to schedule an appointment or request a quote." />
      </Helmet>
      <h2 className='serviceTitle'>Our Services</h2> 
      <div className="services-image-container"> 
        {servicesData.map((service, index) => (
            <div key={index} className="service-item">
              <img src={service.image} alt={service.name} />
              <p>{service.name}</p>
            </div>
        ))}
      </div>
    </div>
    
  );
};

export default Services;