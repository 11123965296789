import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import './BookingForm.css';

const BookingForm = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [carModel, setCarModel] = useState('');
  const [service, setService] = useState('');
  const [appointmentDateTime, setAppointmentDateTime] = useState('');
  const [bookingStatus, setBookingStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    try {
      const response = await axios.post('https://rohan-back.onrender.com/book', {
        name,
        phoneNumber,
        email,
        carModel,
        service,
        appointmentDateTime
      });
      if (response && response.data) {
        setBookingStatus(response.data);
        // Reset form fields
        setName('');
        setPhoneNumber('');
        setEmail('');
        setCarModel('');
        setService('');
        setAppointmentDateTime('');

        setTimeout(() => {
          setBookingStatus('');
        }, 3000);
      } else {
        console.error('Empty response received.');
        setBookingStatus('Failed to book appointment. Please try again later.');
      }
    } catch (error) {
      console.error('Error booking appointment:', error.response ? error.response.data : error.message);
      setBookingStatus('Failed to book appointment. Please try again later.');
    }
  };


  return (
    <div id='bookingForm' className="booking-container">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MP2JL63NHS"></script>
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());

          gtag('config', 'G-MP2JL63NHS');
        `}
        </script>
        <meta name="description" content="Get in touch with Rohan Mechanics for all your mobile auto repair and home service needs in Dubai. Contact us today to schedule an appointment or request a quote in Dubai." />
      </Helmet>
      <div className="booking-form">
        <h2 className="title">Book an Appointment</h2>
        <form onSubmit={handleSubmit} className="form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number:</label>
            <input type="tel" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Address:</label>
            <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="carModel">Car Brand:</label>
            <input type="text" id="carModel" value={carModel} onChange={(e) => setCarModel(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="service">Service Required:</label>
            <select id="service" value={service} onChange={(e) => setService(e.target.value)} required>
              <option value="">Select Service</option>
              <option value="Oil Change">Oil Change</option>
              <option value="Brake Services">Brake Services</option>
              <option value="Engine Repair">Engine Repair</option>
              <option value="Battery Repair">Battery Repair</option>
              <option value="Transmission Repair">Transmission Repair</option>
              <option value="Suspension Repair">Suspension Repair</option>
              <option value="Vehicle Electrical Repair">Vehicle Electrical Repair</option>
              <option value="AC Repair and Maintenance">AC Repair and Maintenance</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="appointmentDateTime">Preferred Appointment Date/Time:</label>
            <input type="datetime-local" id="appointmentDateTime" value={appointmentDateTime} onChange={(e) => setAppointmentDateTime(e.target.value)} required />
          </div>
          <div className='button-space'>
            <button type="submit" className="submit-btn">Book Appointment</button>
          </div>
          <h4 className='warning'>⚠️Please, wait for the booking to confirm before closing the browser, Thank you!</h4>
        </form>
        {bookingStatus && <p className="status">{bookingStatus}</p>}
      </div>
    </div>
  );
};

export default BookingForm;
