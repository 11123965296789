import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import './ImageSlider.css';

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 5000); // Auto-advance every 5 seconds
    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToNextSlide = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
  };

  const goToPrevSlide = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="image-slider">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MP2JL63NHS"></script>
        <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)};
          gtag('js', new Date());

          gtag('config', 'G-MP2JL63NHS');
        `}
        </script>
      </Helmet>
      <div className="slider-container">
        <div className="arrow left" onClick={goToPrevSlide}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <img className='brands' src={images[currentIndex]} alt={`Slide ${currentIndex}`} />
        <div className="arrow right" onClick={goToNextSlide}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
