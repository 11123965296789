import React from 'react';
import Home from '../Home/Home';
import AboutUs from '../AboutUs/AboutUs';
import Services from '../Services/Services';
import BookingForm from '../Booking/BookingForm';
import Footer from '../Footer/Footer';

const CombinedPages = () => {
  return (
    <div>
        <Home />
        <AboutUs/>
        <Services/>
        <BookingForm/>
        <Footer/>
    </div>
  )
}

export default CombinedPages